/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import {
    object, array, string, shape, bool,
} from 'prop-types';
import { useSelector } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import { gql, useLazyQuery } from '@apollo/client';
import { getFeatureFlag } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import MenuBuilder from './MenuBuilder/MenuBuilder';
import MenuHeader from './MenuBuilder/SlideNavV2/MenuHeader';
import LeftMenuBot from './LeftMenuBot';
import LeftWelcomeClose from './LeftWelcomeClose';
import TopHeaderMenu from './TopHeaderMenu';
import { getIsAuthenticatedStatus } from '../../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import { getIsBot } from '../../../../../state/ducks/App/App-Selectors';
import useExperimentServiceAttributes from '../../../../helpers/experimentService/useExperimentServiceAttributes';
import { GRAPHQL_ENV } from '../../../../gql';
import SearchBlock from '../../../GraphqlComponents/GraphqlHeader/SearchBlock';

const CONTENT_QUERY = gql`
    query NavigationMenuV2Query($brand: String!, $environment: String!, $contentType: String!, $uid: String!, $targeting: [Targeting]) {
        findContentByUID(brand: $brand, environment: $environment, locale: "en-us", contentType: $contentType, uid: $uid, targeting: $targeting) {
            content
            experiments
        }
    }
`;

const useStyles = makeStyles((theme) => {
    const mobileMenuBG = theme.palette.mobileMenuBG ? theme.palette.mobileMenuBG : null;
    return ({
        searchForm: {
            paddingTop: '6px',
            borderBottom: '1px solid #E9E9E9',
        },
        contDrawer: {
            overflowX: 'hidden',
        },
        drawerNew: {
            width: '100%',
            background: 'none',
            height: '100%',
            fontFamily: theme.typography?.mobileMenu?.fontFamily || theme.typography?.fontFamily,
            minWidth: '280px',
            maxWidth: '335px',
            fontWeight: '400',
            overflow: 'auto', // this classed wrapped in flag for new menu layout
        },
        drawerContainerNew: {
            overflow: 'hidden', // stopping scroll for whole box, scroll will be done next element.. this classed wrapped in flag for new menu layout
        },
        drawer: {
            width: '100%',
            background: mobileMenuBG ? mobileMenuBG.leftMenuBG : '#909090',
            height: '100%',
            fontFamily: theme.typography?.fontFamily,
            minWidth: '280px',
            maxWidth: '300px',
            fontWeight: '400',
        },
        leftMenu: {
            marginLeft: '0',
        },
        menuIcon: {
            color: theme.palette.colorIconHeader,
            width: '30px',
        },
        clsHeightWidth: {}, // do not delete used for css specificity
        flowersMenuIcon: {
            '& img': {
                '&$clsHeightWidth': {
                    marginBottom: '3px',
                },
            },
        },
        titleIcon: {
            fontSize: '10px',
            fontFamily: 'Lato',
            display: 'block',
            textTransform: 'uppercase',
            marginTop: '3px',
            padding: '0 0 0 0',
            color: theme.palette.cms?.mobileMenuTextColor || theme.palette.mobileMenuColor,
            textAlign: 'center',
        },
        titleIconRaised: {
            fontSize: '10px',
            fontFamily: 'Lato',
            display: 'block',
            textTransform: 'uppercase',
            color: theme.palette.cms?.mobileMenuTextColor || theme.palette.mobileMenuColor,
            textAlign: 'center',
        },
    });
});

// TODO: replace these with feature flag
const menuHeaderBrands = ['HD', 'WLF', 'SY', 'CCO'];

//
// The "hamburger" menu displayed at top-left of screen
// It can take input from either graphql/CMS (PWA system) or composer (WCS-based).
// See MenuBuilder component for more details about menu array.
//
// Props:
//    menu               if PWA, hierarchical array of menu items (hdr.data.findHeader.content.entries[0].header_blocks[0].menu.menu_ref[0].menu_blocks)
//                       if composer, flat array of menu items
//    brand              brand object (must contain brand.code)
//    searchInputTerm    search string that user has entered (optional)
//
const LeftMenu = ({
    menu,
    menuIcon,
    brand,
    searchInputTerm,
    guidedNav,
    logo,
    keeperData,
    contentType,
    uid,
    isSearchUiInMobileMenuEnabled,
    mobileMenuCampaignResponse,
}) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const  handleClickMenu = () => {
        setOpen(!open);
    };
    const isBot = useSelector(getIsBot);
    const isAuthenticated = useSelector(getIsAuthenticatedStatus);
    const isMobileMenuV2Enabled = useSelector(getFeatureFlag('is-mobile-menu-v2-enabled'));
    const cssTitleIcon = menuHeaderBrands.includes(brand.code)
        ? classes.titleIconRaised
        : classes.titleIcon;
    const {
        targeting, context, isGraphqlTargetingEnabled, experimentService,
    } = useExperimentServiceAttributes({ queryName: 'mainNavigation' });

    const variables = {
        brand: brand.domain,
        contentType,
        uid,
        environment: GRAPHQL_ENV,
        ...(isGraphqlTargetingEnabled ? { targeting } : {}),
    };

    const [loadData, {
        data,
    }] = useLazyQuery(CONTENT_QUERY, { variables, context });

    // run only if experiments service (mainNavigation) is enabled
    useEffect(() => {
        if (!isBot && experimentService) {
            loadData();
        }
    }, [experimentService]);

    const widget = data?.findContentByUID?.content?.entry?.menu_blocks?.find((widgetData) => widgetData?.widgets) || menu?.find((widgetData) => widgetData?.widgets);
    const search = widget?.widgets?.name === 'Search';

    const experiments = data?.findContentByUID?.experiments || [];

    // Pre-Render Menu for bots
    if (isBot) {
        return (
            <LeftMenuBot
                isBot={isBot}
                menu={menu}
                menuIcon={menuIcon}
                brand={brand}
                searchInputTerm={searchInputTerm}
                isMobileMenuV2Enabled={isMobileMenuV2Enabled}
                isAuthenticated={isAuthenticated}
                cssTitleIcon={cssTitleIcon}
                open={open}
                handleClickMenu={handleClickMenu}
            />
        );
    }

    return (
        <>
            <TopHeaderMenu
                menu={data?.findContentByUID?.content?.entry?.menu_blocks || menu}
                menuIcon={menuIcon}
                brand={brand}
                searchInputTerm={searchInputTerm}
                cssTitleIcon={cssTitleIcon}
                isAuthenticated={isAuthenticated}
                handleClickMenu={handleClickMenu}
            />
            <Drawer
                classes={{ paper: `${classes.contDrawer} ${isMobileMenuV2Enabled ? classes.drawerContainerNew : ''}` }}
                open={open}
                onClose={() => handleClickMenu()}
            >
                {isMobileMenuV2Enabled && (<MenuHeader isAuthenticated={isAuthenticated} logo={logo} menu={data?.findContentByUID?.content?.entry?.menu_blocks || menu} handleClickMenu={handleClickMenu} />)}
                <div className={`${isMobileMenuV2Enabled ? classes.drawerNew : classes.drawer}`}>
                    {!isMobileMenuV2Enabled && search && <LeftWelcomeClose brand={brand} handleClickMenu={handleClickMenu} />}
                    {isSearchUiInMobileMenuEnabled && (
                        <div className={classes.searchForm}>
                            <SearchBlock
                                brand={brand}
                                typeSearchBox="header"
                                searchButtonClick={handleClickMenu}
                                mobileMenuCampaignResponse={mobileMenuCampaignResponse}
                            />
                        </div>
                    )}
                    <div className={classes.leftMenu}>
                        <MenuBuilder
                            menu={data?.findContentByUID?.content?.entry?.menu_blocks || menu}
                            brand={brand}
                            handleClose={handleClickMenu}
                            target="Mobile"
                            guidedNav={guidedNav}
                            isMobileMenuV2Enabled={isMobileMenuV2Enabled}
                            keeperData={keeperData}
                            experiments={experiments}
                        />
                    </div>
                </div>
            </Drawer>
        </>
    );
};

LeftMenu.propTypes = {
    menu: array.isRequired,
    searchInputTerm: string,
    brand: shape({
        code: string.isRequired,
    }).isRequired,
    menuIcon: object,
    guidedNav: array,
    logo: shape({
        image: shape({
            url: string,
        }),
    }),
    keeperData: object,
    contentType: string,
    uid: string,
    isSearchUiInMobileMenuEnabled: bool,
    mobileMenuCampaignResponse: object,
};

LeftMenu.defaultProps = {
    searchInputTerm: '',
    menuIcon: { name: null },
    guidedNav: [],
    logo: {},
    keeperData: {},
    contentType: '',
    uid: '',
    isSearchUiInMobileMenuEnabled: false,
    mobileMenuCampaignResponse: {},
};

export default LeftMenu;
